<template>
  <div class="sale-container">
    <!-- navbar -->
    <QbNavbar title="销讲大师计划"></QbNavbar>
    <!-- title -->
    <QbTitle
      title="销讲大师计划"
      subtitle="Quanbang Group sale speak master plan"
    ></QbTitle>
    <!-- sale-video -->
    <SaleVideo></SaleVideo>
    <!-- sale-lecturer -->
    <SaleLecturer></SaleLecturer>
    <!-- sale-reserve -->
    <SaleReserve></SaleReserve>
    <!-- sale-schedule -->
    <SaleSchedule></SaleSchedule>
    <!-- sale-content -->
    <SaleContent></SaleContent>
    <!-- sale-conslusion -->
    <SaleConslusion></SaleConslusion>
    <!-- qb-application -->
    <qb-application id="sale-application"></qb-application>
  </div>
</template>

<script>
import SaleVideo from './components/sale-video.vue'
import SaleLecturer from './components/sale-lecturer.vue'
import SaleReserve from './components/sale-reserve.vue'
import SaleSchedule from './components/sale-schedule.vue'
import SaleContent from './components/sale-content.vue'
import SaleConslusion from './components/sale-conslusion.vue'
export default {
  name: 'CourseSale',
  components: {
    SaleVideo,
    SaleLecturer,
    SaleReserve,
    SaleSchedule,
    SaleContent,
    SaleConslusion
  },
  data() {
    return {}
  }
}
</script>

<style lang="less" scoped></style>
