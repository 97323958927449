<template>
  <div class="reserve-container">
    <div class="logo"></div>
    <div class="title">掌握销讲 赋能招商</div>
    <div class="introdution">
      作为一名企业家，无论是在公司内部开会还是对外招商谈判，最不可缺少的能力就是销讲能力，这也是泉邦倾情打造《销讲大师计划》的原因。<br />
      挖掘你的潜能，用销讲的魅力征服世界，让你不仅能“讲”还能“销”，让你拥有公众演说能力和超强销售能力，将原本的招商能力放大数十倍。
    </div>
    <div class="btn" @click="handleGoApplication"></div>
  </div>
</template>

<script>
export default {
  name: 'SaleReserve',
  methods: {
    handleGoApplication() {
      document.querySelector('#sale-application').scrollIntoView()
    }
  }
}
</script>

<style lang="less" scoped>
.reserve-container {
  width: 375px;
  height: 389px;
  background: #f7f9fd;
  padding: 80px 50px 0 48px;
  .logo {
    width: 26px;
    height: 28px;
    background: url(~@/assets/images/fission/reserve-logo.png);
    background-size: cover;
    margin-bottom: 10px;
  }
  .title {
    font-size: 23px;
    font-weight: 500;
    color: #010343;
    line-height: 32px;
    margin-bottom: 33px;
  }
  .introdution {
    width: 277px;
    font-size: 10px;
    font-family: PingFangSC-Regular, PingFang SC;
    font-weight: 400;
    color: #010343;
    line-height: 20px;
    margin-bottom: 25px;
  }
  .btn {
    width: 104px;
    height: 33px;
    background: url(~@/assets/images/fission/reserve-btn.png);
    background-size: cover;
  }
}
</style>
