<template>
  <div class="conclusion-container">
    挖掘你的潜能，用销讲的魅力征服世<br />
    界，让你不仅能“讲”还能“销”
  </div>
</template>

<script>
export default {
  name: 'LandConclusion'
}
</script>

<style lang="less" scoped>
.conclusion-container {
  width: 375px;
  height: 109px;
  background: #1651dd;
  text-align: center;
  font-size: 18px;
  font-weight: 700;
  color: #ffffff;
  line-height: 28px;
  padding: 27px 0;
  margin-top: 27px;
}
</style>
