<template>
  <div class="sale-content-container">
    <h3 class="title">如何用有逻辑的演讲 建立起你的话语权？</h3>
    <div class="introdution">
      通过逻辑清晰的正确演讲，表达我们内心的思想，建立起话语权，拉近我们与听众的距离，从而获得听众的认可。这里的听众，既可以是自己企业的员工，亦可以是拥有潜在资源的客户对象。
      <br />学会演讲可以说是企业经营和品牌推广过程中不能避免的一环。
    </div>
    <div class="to-inside">
      <img src="@/assets/images/sale/sale-inside.png" alt="" />
      <div class="can">
        <div>
          鼓舞员工士气<br />
          增加团队凝聚力
        </div>
        <div class="img"></div>
      </div>
    </div>
    <div class="to-outside">
      <img src="@/assets/images/sale/sale-outside.png" alt="" />
      <div class="can">
        <div>
          吸引招商投资<br />
          为谈判增加筹码
        </div>
        <div class="img"></div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: 'SaleContent'
}
</script>

<style lang="less" scoped>
.sale-content-container {
  padding: 0 20px;
  .title {
    font-size: 24px;
    font-weight: 700;
    color: #010343;
    line-height: 33px;
    text-align: center;
  }
  .introdution {
    font-size: 18px;
    font-weight: 400;
    color: #010343;
    line-height: 36px;
    text-align: center;
    margin-top: 3px;
    margin-bottom: 30px;
  }
  .to-inside {
    margin-bottom: 18px;
  }
  .can {
    font-size: 18px;
    font-weight: 400;
    color: #010343;
    display: flex;
    justify-content: space-between;
    div {
      text-align: right;
      padding-left: 9px;
      padding-top: 66px;
    }
  }
  .img {
    width: 179px;
    height: 113px;
  }
}
</style>
